import React from "react";

import PropTypes from "prop-types";
import { Switch, Redirect, Route } from "react-router-dom";

import applicationConfig from "../../config/applicationConfig";

import pageAccessConfig from "../../config/pageAccessConfig";
import useUserProfile from "../../hooks/useUserProfile";
import checkUserAuthorization from "../../utilities/checkUserAuthorization";

import ConsentMappingsContainer from "./ConsentMappingsContainer";
import ConsentsContainer from "./ConsentsContainer";
import NewConsentContainer from "./NewConsentContainer";
import NewConsentTemplateContainer from "./NewConsentTemplateContainer";

const ConsentsModule = React.memo(({ basePath }) => {
  const { user } = useUserProfile();
  const isUserAuthorized = checkUserAuthorization(
    user.access,
    pageAccessConfig.searchConsentTemplates
  );
  return (
    <Switch>
      <Route
        exact
        path={`${basePath}`}
        component={({ location: { search } }) => {
          if (
            search.includes(applicationConfig.modules.templates) &&
            isUserAuthorized
          ) {
            return <ConsentsContainer />;
          }
          return <ConsentMappingsContainer />;
        }}
      />
      <Route exact path={`${basePath}`} component={ConsentMappingsContainer} />
      <Route
        exact
        path={`${basePath}/templates/manage`}
        render={() => <NewConsentTemplateContainer />}
      />
      <Route
        exact
        path={`${basePath}/manage`}
        render={() => <NewConsentContainer />}
      />
      <Route
        exact
        path={`${basePath}/*`}
        render={() => <Redirect to={`${basePath}`} />}
      />
    </Switch>
  );
});

ConsentsModule.defaultProps = {
  basePath: applicationConfig.basePaths.consent,
};

ConsentsModule.propTypes = {
  basePath: PropTypes.string,
};

export default ConsentsModule;
