import { useState, useRef } from "react";

import { Paper, Typography, Tab, Tabs } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import getConsentTemplates from "../../../api/get-consent-templates";

import useGlobalStyles from "../../../assets/styles/global";

import AccessDenied from "../../../components/AccessDenied";
import Button from "../../../components/Button";
import Layout from "../../../components/Layout";
import SearchFilter from "../../../components/SearchFilter";
import StyledTooltip from "../../../components/StyledTooltip";
import Table from "../../../components/Table";

import consentsModuleConfig from "../../../config/consentsModuleConfig";
import pageAccessConfig from "../../../config/pageAccessConfig";

import useLoadingSpinner from "../../../hooks/useLoadingSpinner";
import useUserProfile from "../../../hooks/useUserProfile";

import checkUserAuthorization from "../../../utilities/checkUserAuthorization";
import parseSearchFilter from "../../../utilities/parseSearchFilter";

import useStyles from "../../Traits/TraitsContainer/styles";

import ConsentTemplateVersionsModal from "./components/ConsentTemplateVersionsModal";

const ConsentsContainer = () => {
  const {
    consentTableColumns,
    consentSearchFilters,
    consentSearchDefaultFilterState,
  } = consentsModuleConfig;
  const classes = useStyles();
  const globalStyles = useGlobalStyles();
  const history = useHistory();
  const [loadingCellData, setLoadingCellData] = useState(false);
  const [filters, setFilters] = useState({});
  const [tab, setTab] = useState(1);
  const childRef = useRef();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [cellData, setCellData] = useState({
    data: [],
    field: "consentTemplateKey",
    row: {},
  });

  const { user } = useUserProfile();
  const { t } = useTranslation();
  const { loading } = useLoadingSpinner();

  const isUserAuthorized = checkUserAuthorization(
    user.access,
    pageAccessConfig.searchConsents
  );

  const resetFilter = () => {
    childRef.current.clearFilter();
    setFilters({});
  };

  const resetDrawer = () => {
    setDrawerOpen((x) => !x);
    setCellData({
      data: [],
      field: "consentTemplateKey",
      row: {},
    });
  };

  const filterFieldMap = {
    consentTemplateKey: "consentTemplateKey",
    country: "country",
    language: "language",
    consentTemplateText: "consentTemplateText",
    "": "searchText",
  };

  return !loading && !isUserAuthorized ? (
    <AccessDenied />
  ) : (
    <Layout
      header={
        <div className={classes.flexContainer}>
          <Typography variant="h4" gutterBottom>
            {t("consent_container.consent_management")}
          </Typography>
        </div>
      }
    >
      <div className={classes.flexContainer}>
        <Tabs
          value={tab}
          onChange={(_, number) => setTab(number)}
          aria-label="basic tabs"
          classes={{
            indicator: globalStyles.indicator,
          }}
        >
          <Tab label="Consents" onClick={() => history.push("/consents")} />
          {checkUserAuthorization(
            user.access,
            pageAccessConfig.searchConsentTemplates
          ) && (
            <Tab
              label="Templates"
              onClick={() => history.push("/consents?displayTab=templates")}
            />
          )}
        </Tabs>
        <div className={classes.flexContainer}>
          <div className={classes.flexContainer}>
            {checkUserAuthorization(
              user.access,
              pageAccessConfig.manageConsentTemplates
            ) && (
              <Button
                btnText={t("consent_container.create_template")}
                variant="contained"
                classes={{
                  root: globalStyles.btn,
                }}
                goToLink="/consents/templates/manage"
                style={{
                  marginRight: "34px",
                }}
              />
            )}
            <SearchFilter
              ref={childRef}
              onSearch={(data) => {
                setFilters(data);
              }}
              searchFilters={consentSearchFilters}
              defaultFilterState={consentSearchDefaultFilterState}
            />
            <StyledTooltip
              placement="top"
              title={t("common.labels.clear_search")}
            >
              <div
                role="button"
                aria-hidden="true"
                data-testid="reset-btn"
                onClick={resetFilter}
              >
                <ClearIcon data-testid="filter-icon" />
              </div>
            </StyledTooltip>
          </div>
        </div>
      </div>
      <Paper data-testid="traits-container" elevation={2}>
        <div className={classes.traitsTableContainer}>
          <Table
            initApiCall={(page, perPage) =>
              getConsentTemplates(
                null,
                parseSearchFilter([filters], filterFieldMap, page, perPage)
              )
            }
            moduleName="consents"
            filters={filters}
            columns={consentTableColumns}
            setLoadingCellData={setLoadingCellData}
            renderCellData={(data, field, row) =>
              setCellData({ data, field, row })
            }
            exportMainSheetName="consents"
            openModal={() => setDrawerOpen(true)}
          />
        </div>
      </Paper>
      {cellData.field === "consentTemplateKey" && drawerOpen && (
        <ConsentTemplateVersionsModal
          title={`${t("common.labels.consent_template_key")} - ${
            cellData.row.consentTemplateKey
          }`}
          subtitle={t("consent_container.consent_template_key_title")}
          data={cellData.data}
          loading={loadingCellData}
          open={drawerOpen}
          onClose={() => resetDrawer()}
        />
      )}
    </Layout>
  );
};

export default ConsentsContainer;
