const accessConfig = {
  readTraits: "traits:read",
  writeTraits: "traits:write",
  approveTraits: "traits:approve",
  readDataSources: "data-sources:read",
  writeDataSources: "data-sources:write",
  approveDatasources: "data-sources:approve",
  readOpts: "opts:read",
  writeOpts: "opts:write",
  approveOpts: "opts:approve",
  readMarketingPrograms: "marketing-programs:read",
  writeMarketingPrograms: "marketing-programs:write",
  approveMarketingPrograms: "marketing-programs:approve",
  readJobs: "jobs:read",
  manageJobs: "jobs:admin",
  readEvents: "events:read",
  writeEvents: "events:write",
  approveEvents: "events:approve",
  readConsents: "consents:read",
  writeConsents: "consents:write",
  approveConsents: "consents:approve",
  readConsentTemplates: "consent-templates:read",
  writeConsentTemplates: "consent-templates:write",
  approveConsentTemplates: "consent-templates:approve",
};

const pageAccessConfig = {
  searchUsecase: [accessConfig.readTraits, accessConfig.readEvents],
  createUsecase: [accessConfig.writeTraits, accessConfig.writeEvents],
  searchTraits: [accessConfig.readTraits],
  createTraits: [accessConfig.writeTraits],
  searchSources: [accessConfig.readDataSources],
  createSources: [accessConfig.writeDataSources],
  dummy: [],
  myRequests: [
    accessConfig.writeTraits,
    accessConfig.writeDataSources,
    accessConfig.writeOpts,
    accessConfig.writeMarketingPrograms,
    accessConfig.writeConsents,
  ],
  myApprovals: [
    accessConfig.approveTraits,
    accessConfig.approveDatasources,
    accessConfig.approveOpts,
    accessConfig.approveMarketingPrograms,
    accessConfig.approveConsents,
    accessConfig.approveConsentTemplates,
    accessConfig.manageJobs,
  ],
  searchOpts: [accessConfig.readOpts],
  createOpts: [accessConfig.writeOpts],
  searchMarketingPrograms: [accessConfig.readMarketingPrograms],
  createMarketingPrograms: [accessConfig.writeMarketingPrograms],
  searchJobs: [accessConfig.readJobs],
  usePostman: [accessConfig.readJobs],
  manageJobs: [accessConfig.manageJobs],
  searchEvents: [accessConfig.readEvents],
  useEvents: [accessConfig.writeEvents],
  searchConsents: [accessConfig.readConsents],
  manageConsents: [accessConfig.writeConsents],
  searchConsentTemplates: [accessConfig.readConsentTemplates],
  manageConsentTemplates: [accessConfig.writeConsentTemplates],
  useSwagger: [
    accessConfig.readTraits,
    accessConfig.readDataSources,
    accessConfig.readOpts,
    accessConfig.readMarketingPrograms,
    accessConfig.readEvents,
    accessConfig.readJobs,
  ],
  useGraphql: [
    accessConfig.writeTraits,
    accessConfig.writeDataSources,
    accessConfig.writeOpts,
    accessConfig.writeMarketingPrograms,
    accessConfig.writeEvents,
    accessConfig.manageJobs,
  ],
};

export default pageAccessConfig;
